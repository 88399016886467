import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

import _4caa2d0c from '../pages/blog/index.vue'
import _5c5bd826 from '../pages/car-results.vue'
import _4e402765 from '../pages/loading.vue'
import _3d1a951f from '../pages/results.vue'
import _46a71255 from '../pages/static.vue'
import _99e16276 from '../pages/blog/page.vue'
import _a617d754 from '../pages/blog/post.vue'
import _0c19e43b from '../pages/index.vue'

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _4caa2d0c,
    name: "blog"
  }, {
    path: "/car-results",
    component: _5c5bd826,
    name: "car-results"
  }, {
    path: "/loading",
    component: _4e402765,
    name: "loading"
  }, {
    path: "/results",
    component: _3d1a951f,
    name: "results"
  }, {
    path: "/static",
    component: _46a71255,
    name: "static"
  }, {
    path: "/blog/page",
    component: _99e16276,
    name: "blog-page"
  }, {
    path: "/blog/post",
    component: _a617d754,
    name: "blog-post"
  }, {
    path: "/",
    component: _0c19e43b,
    name: "index"
  }, {
    path: "/privacy",
    component: _46a71255,
    name: "privacy"
  }, {
    path: "/terms",
    component: _46a71255,
    name: "terms"
  }, {
    path: "/about",
    component: _46a71255,
    name: "about"
  }, {
    path: "/contact",
    component: _46a71255,
    name: "contact"
  }, {
    path: "/flights",
    component: _4caa2d0c,
    name: "articles_index_page"
  }, {
    path: "/flights/:page(\\d+)?",
    component: _4caa2d0c,
    name: "articles_index_page"
  }, {
    path: "/flights/:slug",
    component: _99e16276,
    name: "articles_page_page"
  }, {
    path: "/blog/:hasPage(page)?/:page(\\d+)?",
    component: _4caa2d0c,
    name: "articles_index_page"
  }, {
    path: "/blog/:category/:hasPage(page)?/:page(\\d+)?",
    component: _4caa2d0c,
    name: "articles_category_page"
  }, {
    path: "/blog/:category/author/:author/:hasPage(page)?/:page(\\d+)?",
    component: _4caa2d0c,
    name: "articles_author_page"
  }, {
    path: "/blog/:category/:slug?",
    component: _a617d754,
    name: "articles_post_page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
