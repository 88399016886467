import { render, staticRenderFns } from "./AppSearchForm.vue?vue&type=template&id=3a9e0fd4"
import script from "./AppSearchForm.vue?vue&type=script&lang=js"
export * from "./AppSearchForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlightsSearchForm: require('/home/forge/dev.farescraper.com/components/FlightsSearchForm.vue').default,CarsSearchForm: require('/home/forge/dev.farescraper.com/components/CarsSearchForm.vue').default,HotelsSearchForm: require('/home/forge/dev.farescraper.com/components/HotelsSearchForm.vue').default})
